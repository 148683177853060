.id-front-wrapper {
  width: 500px;
  height: 285px;
}

.kazam-logo-id {
  width: 120px;
  height: 55px;
  position: absolute;
  left: 33px;
  top: 60px;
}

.id-text-heading {
  color: #565656;
  position: absolute;
  left: 39px;
  top: 119px;
}

.id-code-web {
  position: absolute;
  top: 202px;
  left: 34px;
}

.kazam-url {
  position: absolute;
  top: 253px;
  left: 24px;
  color: #5b5c5c;
}

.id-back-wrapper {
  width: 500px;
  height: 285px;
}

.name-id {
  position: absolute;
  left: 28px;
  top: 65px;
}

.des-id {
  position: absolute;
  left: 28px;
  top: 100px;
}

.kazam-full {
  position: absolute;
  top: 18px;
  left: 411px;
}

.kazam-full-img {
  width: 72px;
  height: 20px;
}

.id-address {
  position: absolute;
  color: #5b5c5c;
  left: 311px;
  top: 66px;
}

.id-qr2 {
  position: absolute;
  top: 147px;
  left: 319px;
}

.id-qr2-img {
  width: 59px;
  height: 59px;
}

.kazam-url2 {
  color: #5b5c5c;
  position: absolute;
  top: 212px;
  left: 311px;
}

.phone-id {
  position: absolute;
  left: 28px;
  top: 179px;
}
.email-id {
  position: absolute;
  left: 28px;
  top: 204px;
  width: 200px;
}

.kazam-app-text {
  color: #5b5c5c;
  position: absolute;
  top: 263px;
  left: 311px;
}

.google-id {
  position: absolute;
  top: 264px;
  left: 394px;
}

.google-id-img {
  width: 45px;
  height: 13px;
}

.apple-id {
  position: absolute;
  top: 264px;
  left: 446px;
}

.apple-id-img {
  width: 45px;
  height: 13px;
}
