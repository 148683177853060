@media screen and (min-width: 1024px) {
  .academy_header {
    height: 60vh;
  }
}

.headerImg {
  filter: drop-shadow(10px 10px 20px #646cffaa);
}

.nav_dropdown .nav_sub {
  display: none;
}

.nav_dropdown:hover .nav_sub {
  display: block;
}

.desc_wrapper {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}
