/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
} */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #e7e7e7;
}

::-webkit-scrollbar-thumb {
  background: #666666;
}
.tooltip {
  z-index: 100;
  display: none;

  animation: fadein 0.2s linear;
}

.icon_wrapper:hover .tooltip {
  display: block;
}
.button_wrapper:hover .tooltip {
  display: block;
}
.zoom_wrapper {
  animation: fadein 0.2s linear;
}
@keyframes fadein {
  0% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

.slide_drawer_rtl {
  animation: slideLeft 0.2s linear;
}

@keyframes slideLeft {
  0% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0%);
  }
}
.slide_drawer_ltr {
  animation: slideRight 0.2s linear;
}

.target_drawer {
  animation: slideLeft 0.2s linear;
}

@keyframes slideRight {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}

.sticky-row:hover .sticky-field {
  background: #e5e7eb;
}

.sticky-field {
  background: white;
}
.grey {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

/* thead {
  font-size: 14px;
  background: rgb(37, 37, 37);
  color: white;
}
td {
  padding: 4px 8px 4px 8px;
  white-space: nowrap;
  font-size: 12px;
} */
.slide_down {
  animation: slideDown 0.2s linear;
}

@keyframes slideDown {
  0% {
    transform: translateY(-20%);
  }
  50% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0%);
  }
}

@media (max-width: 1024px) {
  .h-89 {
    height: 89.5vh;
  }
}

.flip {
  text-align: center;
  perspective: 600px;
}
.flip-content {
  width: 100%;
  height: 100%;
  transition: transform 0.4s;
  transform-style: preserve-3d;
}
.flip:hover .flip-content {
  transform: rotateY(180deg);
  transition: transform 0.3s;
}

.flip-front,
.flip-back {
  position: absolute;
  height: 100%;
  width: 100%;
  line-height: 150px;
  backface-visibility: hidden;
}
.flip-back {
  transform: rotateY(180deg);
}

.login_left_screen {
  /* background-color: #590000; */
  /* background-color: #00bea5; */
  /* background: #262626; */
  background: #1f1f1f;
  /* color: #bebebe; */
}

.login_card {
  /* transform: translateX(-100%); */
  animation: slideRight 0.5s linear;
  background: #262626;
}

.detail-container {
  animation: h_0 0.5s linear;
  height: 0;
}
@keyframes h_0 {
  0% {
    height: auto;
  }
  100% {
    height: 0;
  }
}
table {
  border-collapse: collapse;
}

.visibility_toggle {
  animation: showModal 0.5s linear forwards;
}

@keyframes showModal {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.modal {
  animation: zoomIn 0.3s linear forwards;
}

@keyframes zoomIn {
  0% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

.flicker {
  animation: flicker 0.4s linear infinite;
}

@keyframes flicker {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

.values div:nth-child(1) {
  animation: showModal 0.5s linear forwards;
  animation-delay: 0.5s;
  opacity: 0;
}

.values div:nth-child(2) {
  animation: showModal 0.5s linear forwards;
  animation-delay: 1s;
  opacity: 0;
}
.values div:nth-child(3) {
  animation: showModal 0.5s linear forwards;
  animation-delay: 1.5s;
  opacity: 0;
}
.values div:nth-child(4) {
  animation: showModal 0.5s linear forwards;
  animation-delay: 2s;
  opacity: 0;
}
.values div:nth-child(5) {
  animation: showModal 0.5s linear forwards;
  animation-delay: 2.5s;
  opacity: 0;
}

.values div:nth-child(6) {
  animation: showModal 0.5s linear forwards;
  animation-delay: 3s;
  opacity: 0;
}


.wave_svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: block;
  background-color: #0e4166;
  background-image: linear-gradient(to bottom, rgba(14, 65, 102, 0.86), #0e4166);
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* border: none;
  -webkit-text-fill-color: #181718; */

   border: 1px solid green;
  -webkit-text-fill-color: green;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}


.no-arrow::-webkit-outer-spin-button,
.no-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}