/* latin-ext */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/lato/v17/S6uyw4BMUTPHjxAwXjeu.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/lato/v17/S6uyw4BMUTPHjx4wXg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/lato/v17/S6u9w4BMUTPHh6UVSwaPGR_p.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/lato/v17/S6u9w4BMUTPHh6UVSwiPGQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  
  .Title {
      font-family: Lato;
      font-size: 48px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      text-align: left;
      color: #131313;
  }
  
  .Image {
      max-width: 600px;
      max-height: 315px;
      object-fit: cover;
    }
  
  .Paragraph {
    font-family: Lato;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #131313;
  }
  
  .subTitle {
    font-family: Lato;
    font-size: 38px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.95;
    letter-spacing: normal;
    text-align: left;
    color: #131313;
  }
  
  .userAction {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
  }
  
  .userAction li {
    font-family: Lato;
    font-size: 12px;
    color: #131313;
    text-decoration: none;
  }
  
  .userAction li button {
    border:none;
    background: transparent;
  }
  
  .userAction li svg:hover, 
  .userAction li svg:hover {
    fill: #777272; 
    stroke:#f4263d; 
    stroke-width: 1px;
    transform: scale(2,2);
  }
  
  .joinNow button{
    font-family: Lato;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #f4263d;
    background-color: #ffffff;
    padding: 10px 40px 10px 40px;
    border-radius: 8px;
    border: solid 1px #f4263d;
  }
  
  .joinNow p {
    font-family: Lato;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #959595;
  }
  
  .joinNow h6 {
    font-family: Lato;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }
  
  .joinNow {
    margin-top: 405px;
  }
  
  .input {
    opacity: 0.9;
    font-family: Lato;
    font-size: 18px;
    color: #f4263d;
    background: #f3f2f2;
    border-radius: 30px;
    text-align: center;
    border: none;
    padding: 10px 0 10px 0;
    margin-bottom: 20px;
  }
  
  @media screen and (max-width: 720px) {
    .joinNow {
      margin-top: 0px;
    }
  
    .Image {
      max-width: 400px;
      max-height: 200px;
      object-fit: cover;
    }
  }
  
  @media screen and (max-width: 350px) {
    .Image {
      max-width: 300px;
      max-height: 100px;
      object-fit: cover;
    }
  }