@keyframes flicker {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* .link {
  position: relative;
} */

.flicker {
  /* position: absolute;
  top: -12px;
  right: -20px;
  background: #a60614;
  border: 1px solid #a60614;
  border-radius: 4px;
  font-size: 10px;
  padding: 1px 4px; */
  animation: flicker 1.1s infinite;
}
