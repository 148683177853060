@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,600;0,700;0,800;1,400;1,600&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap"); */

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}

* {
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
}
