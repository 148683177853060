.logo {
  animation: slideRight 0.4s linear forwards;
}

.sidePanel {
  animation: slideRight 0.2s linear forwards;
}
@keyframes slideLeft {
  0% {
    transform: translateX(200px);
  }
  50% {
    transform: translateX(100px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}

.wishcard {
  animation: wishZoom 0.3s linear forwards;
  animation-delay: 0.7s;
  opacity: 0;
}

@keyframes wishZoom {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  50% {
    transform: scale(0.9);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.wishCardDesc {
  animation: wishDescZoom 0.8s linear forwards;
  opacity: 0;
}

@keyframes wishDescZoom {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.homeCard {
  /* background: #96adcf; */
  background: #262626;
  /* animation: zoom 0.3s forwards; */
  /* opacity: 0; */
  transition: 0.3s all ease-in-out;
}

.homeCard:hover {
  background: #1b1b1b;
}

.access_check {
  visibility: hidden;
}

.homeCard:hover .access_check {
  visibility: visible;
}

.homeCard:nth-child(1) {
  animation-delay: 0.2s;
}
.homeCard:nth-child(2) {
  animation-delay: 0.4s;
}
.homeCard:nth-child(3) {
  animation-delay: 0.6s;
}
.homeCard:nth-child(4) {
  animation-delay: 0.8s;
}
.homeCard:nth-child(5) {
  animation-delay: 1s;
}
.homeCard:nth-child(6) {
  animation-delay: 1.2s;
}
.homeCard:nth-child(7) {
  animation-delay: 1.4s;
}
.homeCard:nth-child(8) {
  animation-delay: 1.6s;
}

@keyframes zoom {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.quote {
  animation: fadeInOut 0.8s linear forwards;
  opacity: 0;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.card_wrapper .card_div {
  animation: popUp 0.4s linear forwards;
  opacity: 0;
}

.card_wrapper .card_div {
  /* animation-delay: calc(attr(data-count) * 150ms); */
  animation-delay: calc(0.1s * var(--i));
}

@keyframes popUp {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(0.9);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* .marquee {
  width: 100%;
  padding: 8px 0;
  background-color: rgb(160, 5, 5);
  color: white;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 999;
} */

.marquee .marquee_wrapper {
  display: flex;
  justify-content: space-around;
  /* padding-left: 100%; */
  width: 80%;
  font-weight: 800;
  animation: marquee 20s linear infinite;
  animation-delay: 1.5s;
}

@media screen and (max-width: 1024px) {
  .marquee .marquee_wrapper {
    animation: marquee 10s linear infinite;
  }
}

.marquee_wrapper p {
  margin-left: 30px;
}
@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.kaizuImg {
  animation: appearIn 0.5s linear forwards;
  opacity: 0;
}

.kaizuText {
  animation: appearIn 0.5s linear forwards;
  animation-delay: 0.5s;
  opacity: 0;
}

@keyframes appearIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.slide2 {
  animation: slideOut 0.7s linear forwards;
  animation-delay: 8s;
}

@keyframes slideOut {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-250px);
  }
  100% {
    transform: translateX(-500px);
  }
}

.landing_dropdown .nav_sub {
  display: none;
}

.landing_dropdown:hover .nav_sub {
  display: block;
}
