.light-calendar ::-webkit-calendar-picker-indicator {
  filter: invert(0);
}

.dark-calendar ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.sidebar {
  width: 100vw;
}

@media screen and (min-width: 1024px) {
  .sidebar {
    width: 16vw;
  }

  .sidebar_collapse {
    width: 5vw;
  }
}

.data_wrapper {
  margin-left: 0;
}

@media screen and (min-width: 1024px) {
  .data_wrapper {
    margin-left: 16vw;
  }

  .data_wrapper_collapsed {
    margin-left: 5vw;
  }
}

@media screen and (min-width: 1024px) {
  .profile_wrapper {
    margin-left: -20vw;
  }
}

.button_animate div:nth-child(1),
.back div:nth-child(1) {
  transition: 0.4s all ease-in-out;
}

.button_animate div:nth-child(2) {
  transform: translateX(110px);
  transition: 0.4s all ease-in-out;
}
.back div:nth-child(2) {
  transform: translateX(50px);
  transition: 0.4s all ease-in-out;
}

.back:hover div:nth-child(1) {
  transform: translateX(-50px);
}
.back:hover div:nth-child(2) {
  transform: translateX(0);
}
.button_animate:hover div:nth-child(1) {
  transform: translateX(-150px);
}
.button_animate:hover div:nth-child(2) {
  transform: translateX(0);
}

.card_bg {
  background: #262626;
  transition: 0.3s all ease-in-out;
}

.card_bg:hover {
  background: #181818;
}

.nav_active {
  /* background: linear-gradient(90.71deg, #ae91f2 7.59%, #75c1ed 98.61%); */
  /* border-left: 5px solid #ae91f2; */
  color: #4b69fa;
  font-weight: 600;
  border-right: 4px solid #4b69fa;
  transition: 0.4s all ease-in-out;
}

/* .nav_active .sidebar_icon {
  visibility: hidden;
} */

.success_msg {
  animation: slideUp 0.4s linear forwards;
}
.success_msg div:nth-child(1) {
  opacity: 0;
  animation: zoom 0.3s linear forwards;
  animation-delay: 0.4s;
}
.success_msg div:nth-child(2) {
  opacity: 0;
  animation: zoom 0.3s linear forwards;
  animation-delay: 0.6s;
}

@keyframes slideUp {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}

.top_img:hover .top_dropdown {
  display: flex;
  animation: zoom 0.2s forwards;
  opacity: 0;
}

.purple_bg {
  background: linear-gradient(90.71deg, #ae91f2 7.59%, #75c1ed 98.61%);
  transition: 0.5s all ease-in-out;
}

.calendar_bg {
  background: linear-gradient(90.71deg, #ae91f2 7.59%, #75c1ed 98.61%);
}

.formModal {
  width: 90vw;
}

@media screen and (min-width: 1024px) {
  .formModal {
    width: 40vw;
  }
}
.formModal {
  animation: zoom 0.2s forwards;
}

.leavecard {
  animation: zoom 0.3s forwards;
  opacity: 0;
}
.birthdayCard {
  /* background: #96adcf; */
  background: #262626;
  animation: appear 0.3s forwards;
  opacity: 0;
  transition: 0.3s all ease-in-out;
}

.activityCardLeft {
  animation: zoom 0.3s forwards;
  opacity: 0;
  animation-delay: 1.4s;
}

.activityCardRight {
  animation: zoom 0.3s forwards;
  opacity: 0;
  animation-delay: 1.6s;
}

.leavecard:nth-child(1) {
  animation-delay: 0.6s;
}
.leavecard:nth-child(2) {
  animation-delay: 0.8s;
}
.leavecard:nth-child(3) {
  animation-delay: 1s;
}
.leavecard:nth-child(4) {
  animation-delay: 1.2s;
}
.leavecard:nth-child(5) {
  animation-delay: 1.6s;
}
.leavecard:nth-child(6) {
  animation-delay: 1.6s;
}
.leavecard:nth-child(7) {
  animation-delay: 1.8s;
}

@keyframes zoom {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* .sidebar {
  animation: slideRight 0.4s linear forwards;
} */

@keyframes appear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}

.leave_circle div {
  opacity: 0;
  transition: 0.2s all ease-in-out;
}
.leave_circle:hover div {
  opacity: 1;
}

.calendar_tool div {
  opacity: 0;
}
.calendar_tool:hover div {
  opacity: 1;
}

.character {
  animation: zoom 0.3s linear forwards;
  opacity: 0;
  animation-delay: 0.6s;
}

/* Responsive design */
@media screen and (min-width: 1024px) {
  .sidebar {
    width: 20 vw;
  }
}

.profileFront {
  display: none;
}

.profileFrontReset {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (min-width: 1024px) {
  .profileFront {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    animation: slideToLeft 0.5s linear forwards;
  }

  .profileBack {
    position: absolute;
    top: 0;
    left: 0;
    animation: slideToRight 0.5s linear forwards;
  }

  .profileFrontReset {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    animation: slideToLeftReset 0.5s linear forwards;
  }

  .profileBackReset {
    position: absolute;
    top: 0;
    left: 0;
    animation: slideToRightReset 0.5s linear forwards;
  }
}

@keyframes slideToLeft {
  0% {
    left: 0vw;
  }
  50% {
    left: -7vw;
  }
  100% {
    left: -15vw;
  }
}

@keyframes slideToRight {
  0% {
    left: 0vw;
  }
  50% {
    left: 7vw;
  }
  100% {
    left: 15vw;
  }
}

@keyframes slideToLeftReset {
  0% {
    left: -15vw;
  }
  50% {
    left: -7vw;
  }
  100% {
    left: 0vw;
  }
}

@keyframes slideToRightReset {
  0% {
    left: 15vw;
  }
  50% {
    left: 7vw;
  }
  100% {
    left: 0vw;
  }
}

.orgchart {
  background-color: #262626;
  width: 100%;
  height: 100%;
  font-size: 2rem;
}

.hrm_loading {
  display: inline-block;
  position: relative;
  /* width: 80px;
  height: 80px; */
}
.hrm_loading div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 70%;
  height: 70%;
  margin: 8px;
  border: 8px solid #4b69fa;
  border-radius: 50%;
  animation: hrm_loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #4b69fa transparent transparent transparent;
}
.hrm_loading div:nth-child(1) {
  animation-delay: -0.45s;
}
.hrm_loading div:nth-child(2) {
  animation-delay: -0.3s;
}
.hrm_loading div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes hrm_loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.memory_card_odd {
  animation: memory_odd 1.2s alternate infinite;
}

.memory_card_even {
  animation: memory_even 1.4s alternate infinite;
}

@keyframes memory_odd {
  0% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes memory_even {
  0% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.memoryAnimation {
  animation: memoryZoom 0.5s linear;
}

@keyframes memoryZoom {
  0% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

/* .hrm_pagination button {
  color: black !important;
} */

.hr_leave_panel_wrapper {
  height: 70vh;
}

@media screen and (min-width: 1024px) {
  .hr_leave_panel_wrapper {
    height: 55vh;
  }
}

.sidebar_animate {
  animation: slidertl 0.3s linear;
}

@keyframes slidertl {
  0% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0%);
  }
}

.hrm_btn:active {
  transform: scale(0.98);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.leave-table-data {
  max-height: 80vh;
}

@media screen and (min-width: 1024px) {
  .leave-table-data {
    max-height: 80vh;
  }
}
/* 
::-webkit-calendar-picker-indicator {
  filter: invert(1);
} */

.activityPending {
  background: linear-gradient(
    90deg,
    rgba(75, 105, 250, 1) 9%,
    rgba(75, 105, 250, 1) 22%,
    rgba(234, 234, 234, 1) 100%
  );
}
