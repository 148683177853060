.progress_line{
    animation: progress_line_anm 3s linear;
    
}


@keyframes progress_line_anm {
    0%{
        width: 100%;
    }
    100%{width: 0%;}
}