.job_applicant_wrapper {
  height: 60vh;
}

@media screen and (min-width: 1080px) {
  .job_applicant_wrapper {
    height: 65vh;
  }
}
/* 
.job_card_title {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: nowrap;
} */
